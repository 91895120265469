// @ts-nocheck
/* eslint-enable */
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import SearchItem from 'app/shared/models/SearchItem';

const searchUtils = {
  getGeoipSearchItem(geolocationArea = {}) {
    return new SearchItem({
      description: 'Use my current location',
      gaEvent: gaEvents.SELECT_GEOIP_SEARCH,
      name: geolocationArea.fullName,
      type: 'geoip',
      resourceId: geolocationArea.resourceId,
      url: '',
    });
  },

  getRecentSearchItem({ recent }) {
    const recentFullName = recent && recent.areaInfo ? recent.areaInfo.fullName : '';
    const recentResourceId = recent && recent.areaInfo ? recent.areaInfo.resourceId : '';
    let recentSearchItem;
    if (!isEmpty(recent)) {
      // very loose check to know if recent is set or not.
      recentSearchItem = new SearchItem({
        description: recent.filterDescription,
        gaEvent: gaEvents.RESUME_RECENT_SEARCH,
        name: recentFullName,
        type: 'recentSearch',
        resourceId: recentResourceId,
        url: recent.url,
      });
    } else {
      recentSearchItem = false;
    }
    return recentSearchItem;
  },

  getSavedSearchItems({ saved }) {
    return map(saved, (savedSearch) => {
      return new SearchItem({
        description: savedSearch.filterDescription,
        gaEvent: gaEvents.RESUME_SAVED_SEARCH,
        name: savedSearch.name,
        numberOfNewResults: savedSearch.numberOfNewResults,
        type: 'savedSearch',
        resourceId: '',
        url: savedSearch.url,
      });
    });
  },

  getSuggestions({ recent, saved, geolocationArea, limit = 1 } = {}) {
    const recentSearchItem = searchUtils.getRecentSearchItem({ recent });
    const geoipSearchItem = searchUtils.getGeoipSearchItem(geolocationArea);
    const savedSearchItems = searchUtils.getSavedSearchItems({ saved });
    let suggestions = [];

    suggestions.push(geoipSearchItem);
    if (recentSearchItem) {
      suggestions.push(recentSearchItem);
    }
    suggestions = suggestions.concat(savedSearchItems);
    return suggestions.slice(0, limit);
  },
};

export default searchUtils;
