// @ts-nocheck
/* eslint-enable */
import transform from 'lodash/transform';
import api from 'app/shared/utils/api';

import DISPATCH_CONSTANTS from 'app/shared/constants/DispatchConstants';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';
import AppActions from 'app/shared/flux/actions/AppActions';
import AreaActions from 'app/shared/flux/actions/AreaActions';
import FetchListingActions from 'app/shared/flux/actions/FetchListingActions';
import queryUtils from 'app/shared/utils/queryUtils';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import routeUtils from 'app/shared/utils/routeUtils';
import SearchItem from 'app/shared/models/SearchItem';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';
import { adapt_reduxToQuery } from 'app/shared/flux/actions/FilterActions/adapters';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('actions/search');

const SearchActions = {
  goToSearchItem(searchItem) {
    return (dispatch, getState) => {
      if (searchItem.gaEvent) {
        dispatch(analyticsEvent(searchItem.gaEvent));
      }
      if (searchItem.type === 'address') {
        // Handle paid MF building URLs.
        const maloneLotIdEncoded = searchItem.url.split('/')[1];
        return dispatch(
          FetchListingActions.fetchListingByMaloneLotIdNoRedirect({
            maloneLotIdEncoded,
            isBuilding: true,
          }),
        ).then((listing) => {
          if (!listing) {
            console.warn(`ERROR: No listing for MaloneLotId: ${maloneLotIdEncoded}`);
            return Promise.resolve({ success: false });
          }

          dispatch(UserItemActions.addUserItem('viewed', listing));

          // HPWEB-2961: Replace uriV2 '/buidling' with '/pad' for paid MF results.
          const buildingUrl = listing.uriV2.split('/');
          buildingUrl[buildingUrl.length - 1] = 'pad';
          listing.uriV2 = buildingUrl.join('/');

          dispatch(RouteActions.transitionToListing({ listingUri: listing.uriV2 }));
          return Promise.resolve({ success: true });
        });
      } else if (searchItem.url && !searchItem.shouldBuildUrl) {
        // saved search & recent search items have a url property
        // shouldBuildUrl === true when the searchItem is a RecentSearch
        window.router.transitionTo(searchItem.url);
        return Promise.resolve({ success: true });
      } else if (searchItem.resourceId) {
        const store = getState();
        const areaResourceId = searchItem.resourceId;
        const border = areaResourceId === 'united-states' ? false : null;
        const filter = store.filter;
        const searchSlug = filter.search.slug;
        const analytics = {
          HPWEB_CONTROL: getState().location.current?.query?.HPWEB_CONTROL,
          HPWEB_EXP: getState().location.current?.query?.HPWEB_EXP,
        };
        const queryObj = adapt_reduxToQuery({ filter, border, analytics });
        const areaPath = routeUtils.buildAreaPath({
          areaResourceId,
          searchSlug,
        });

        let url = areaPath + queryUtils.stringify(queryObj);
        // if search item already has predifined url, combine that with generated url
        url = queryUtils.combineMapQueryParams(queryUtils.removeNonMapQueries(searchItem.url), url);
        dispatch(AppActions.setAppStoreBool('fetchListingsByCoordsComplete', false));
        dispatch({ type: DISPATCH_CONSTANTS.CLEAR_LISTING_CACHE });
        window.router.transitionTo(url);
        return Promise.resolve({ success: true });
      } else if (searchItem.type === 'freeform') {
        return dispatch(SearchActions.getLatLonFromString(searchItem.name)).then((result = {}) => {
          if (result.success) {
            const { lat, lon, zoom } = result;
            const ZOOM_OFFSET = 8;
            return dispatch(AreaActions.getAreaByLatLon({ lat, lon })).then((area = {}) => {
              return dispatch(
                SearchActions.goToLatLonZoom({
                  areaResourceId: area.resourceId,
                  lat,
                  lon,
                  zoom: zoom - ZOOM_OFFSET,
                }),
              );
            });
          } else {
            console.warn('getLatLonFromString no location found.');
            return Promise.resolve({ success: false });
          }
        });
      } else {
        logError({ error: 'Unexpected result in SearchActions#goToSearchItem', errorType: 'SearchActionsError' });
        return Promise.resolve({ success: false });
      }
    };
  },
  goToLatLonZoom({ areaResourceId, lat, lon, zoom, border = true }) {
    return (dispatch, getState) => {
      const filter = getState().filter;
      const page = getState().location.current.query.page;
      const mapPath = routeUtils.buildAreaPath({
        areaResourceId,
        searchSlug: filter.search.slug,
      });
      const mapData = {
        lat,
        lon,
        zoom,
      };
      const analytics = {
        HPWEB_CONTROL: getState().location.current?.query?.HPWEB_CONTROL,
        HPWEB_EXP: getState().location.current?.query?.HPWEB_EXP,
      };
      const queryObj = adapt_reduxToQuery({ filter, mapData, border, page, analytics });
      const url = mapPath + queryUtils.stringify(queryObj);
      window.router.transitionTo(url);
      return Promise.resolve({ success: true });
    };
  },
  goToGeolocation({ lat, lon, zoom }) {
    return (dispatch) => {
      return dispatch(AreaActions.getAreaByLatLon({ lat, lon, zoom })).then((area) =>
        dispatch(SearchActions.goToLatLonZoom({ areaResourceId: area.resourceId, lat, lon, zoom })),
      );
    };
  },
  getLatLonFromString(string = 'new-york-ny') {
    return (dispatch) => {
      return dispatch(api.area.getLatLonFromString(string)).then((result = {}) => {
        const { lat, lon, zoom, message } = result.data;
        if (result.success) {
          return {
            success: true,
            lat,
            lon,
            zoom,
            type: String(message).toLowerCase(),
          };
        }
      });
    };
  },
  getSearchItemFromAutocomplete({ partial, limit, areaTypes = [], lat, lon }) {
    return (dispatch) => {
      let types = areaTypes.concat('address');
      types = types.join(',');

      return dispatch(api.area.autocomplete({ partial, limit, types, lat, lon })).then((results) => {
        return transform(
          results.data,
          (searchItems, suggestion) => {
            const { name = '', type, resourceId, lotIdEncoded, city, county, state } = suggestion;
            let description = city ? city + ', ' + state : state;
            let areaType = type;
            let searchType = 'area-selection';
            let url = '';
            let nameV2 = name.split(',')[0];

            if (type === 'City') {
              // county awaiting HPSERVICE-2478
              description = county ? county + ', ' + state : state;
            } else if (type === 'Address') {
              areaType = 'Property';
              nameV2 = suggestion.name;
              if (lotIdEncoded) {
                url = `/${lotIdEncoded}/building`;
                searchType = 'address';
              }
            }

            searchItems.push(
              new SearchItem({
                areaType,
                description,
                gaEvent: gaEvents.SELECT_AREA_SUGGESTION,
                name,
                nameV2,
                resourceId,
                type: searchType,
                url,
              }),
            );
          },
          [],
        );
      });
    };
  },
};

export default SearchActions;
